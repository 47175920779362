import React from "react";
import { PageProps } from "gatsby";
import { Page } from "@/components/Page";
import { Login } from "@/features/Authentication/Login";
import { withoutAuthentication } from "@/features/Authentication/AccessRestriction";
import { SEO } from "@/components/SEO";

interface LoginPageLocationState {
  email?: string;
}

const LoginPage = ({
  location: { state },
}: PageProps<unknown, unknown, LoginPageLocationState | undefined>) => {
  const email = state?.email;

  return (
    <Page variation="presession">
      <Login defaultEmail={email} />
    </Page>
  );
};

export default withoutAuthentication(LoginPage);

export const Head = () => (
  <SEO
    title="Login"
    description="Access your Jobber Developer account and build apps on top of our platform"
  />
);
