import React, { useState } from "react";
import { InputText } from "@jobber/components/InputText";
import { InputPassword } from "@jobber/components/InputPassword";
import { Content } from "@jobber/components/Content";
import { Button } from "@jobber/components/Button";
import { Heading } from "@jobber/components/Heading";
import { Divider } from "@jobber/components/Divider";
import { Form } from "@jobber/components/Form";
import * as styles from "@/features/Authentication/PresessionCommon/PresessionCommon.module.css";
import { Banner } from "@/components/Banner";
import { ForgotPasswordPrompt, SignupPrompt } from "../components";
import { JobberDevCenterLabel } from "../components/JobberDevCenterLabel";

interface LoginProps {
  isLoading: boolean;
  onLoginAttempt(params: LoginAttemptParameters): void;
  defaultEmail?: string;
}

interface LoginAttemptParameters {
  email: string;
  password: string;
}

export function Login({
  defaultEmail = "",
  isLoading,
  onLoginAttempt,
}: LoginProps) {
  const [inputs, setInputs] = useState({
    email: defaultEmail,
    password: "",
  });

  return (
    <div className={styles.common_wrapper}>
      <Banner />
      <JobberDevCenterLabel />
      <Content>
        <Form onSubmit={handleLoginSubmit}>
          <Content>
            <Heading level={2}>Login</Heading>
            <div className={styles.validation_message}>
              <InputText
                placeholder="Email"
                size="large"
                validations={{
                  required: {
                    value: true,
                    message: "Please provide an email",
                  },
                }}
                defaultValue={defaultEmail}
                onChange={handleInputChange({ input: "email" })}
              />
            </div>
            <div className={styles.validation_message}>
              <InputPassword
                placeholder="Password"
                size="large"
                hasVisibility
                validations={{
                  required: {
                    value: true,
                    message: "Please provide a password",
                  },
                }}
                onChange={handleInputChange({ input: "password" })}
              />
            </div>
            <Button
              label="Log In"
              size="large"
              fullWidth
              submit
              loading={isLoading}
            />
          </Content>
        </Form>
        <div>
          <ForgotPasswordPrompt />
        </div>
        <Divider />
        <div>
          <SignupPrompt />
        </div>
      </Content>
    </div>
  );

  function handleInputChange({ input }: { input: "email" | "password" }) {
    return (value: string) =>
      setInputs(current => ({ ...current, [input]: value }));
  }

  function handleLoginSubmit() {
    onLoginAttempt(inputs);
  }
}
