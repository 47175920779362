/* eslint-disable max-statements */
import React, { useState } from "react";
import { Auth } from "@aws-amplify/auth";
import { showToast } from "@jobber/components/Toast";
import { navigate } from "gatsby";
import { ErrorCodes } from "@/utils/cognito/ErrorCodes";
import { useUserContext } from "@/context/user";
import { useDisplayError } from "@/hooks/useDisplayError";
import { Login } from "./Login";

interface LoginLoaderProps {
  defaultEmail?: string;
}
export function LoginLoader({ defaultEmail }: LoginLoaderProps) {
  const { displayError } = useDisplayError();
  const { setCognitoUser } = useUserContext();
  const [isAttemptingLogin, setIsAttemptingLogin] = useState(false);

  return (
    <Login
      defaultEmail={defaultEmail}
      isLoading={isAttemptingLogin}
      onLoginAttempt={handleLoginAttempt}
    />
  );

  async function handleLoginAttempt({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) {
    setIsAttemptingLogin(true);
    try {
      const user = await Auth.signIn(email, password);
      if (user?.challengeName == "SOFTWARE_TOKEN_MFA") {
        setCognitoUser(user);
        navigate("/login/mfa");
        return;
      }
      showToast({ message: "Logged in", variation: "success" });
    } catch (error: any) {
      const code = error?.code as undefined | ErrorCodes;

      switch (code) {
        case ErrorCodes.USER_NOT_CONFIRMED: {
          navigate("/email_confirmation", { state: { email, password } });
          break;
        }
        default: {
          displayError([
            "Unable to login, contact support for help or try again",
          ]);
        }
      }
    } finally {
      setIsAttemptingLogin(false);
    }
  }
}
